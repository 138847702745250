import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	Fetch,
	IFetchApi,
	IPropsStore,
	IStructureAPI,
	SiteFeatureConfigSymbol,
	Props,
	StructureAPI,
	ViewerModel,
	ViewerModelSym,
	CurrentRouteInfoSymbol,
} from '@wix/thunderbolt-symbols'
import { DialogComponentId, name } from './symbols'
import { IPasswordProtectedPageApi, PasswordProtectedPageSiteConfig, PasswordDialogProps } from './types'
import { ICurrentRouteInfo } from 'feature-router'

const passwordProtectedPageApi = (
	propsStore: IPropsStore,
	structureApi: IStructureAPI,
	fetchApi: IFetchApi,
	siteFeatureConfig: PasswordProtectedPageSiteConfig,
	viewerModel: ViewerModel,
	currentRouteInfo: ICurrentRouteInfo
): IPasswordProtectedPageApi => {
	const { siteRevision, metaSiteId, siteId } = viewerModel.site
	const url = `${siteFeatureConfig.protectedPageResolverUrl}?siteRevision=${siteRevision}`
	let pendingDialogProps: PasswordDialogProps | null = null
	let appMounted = false
	const hideDialog = () => {
		structureApi.removeComponentFromDynamicStructure(DialogComponentId)
	}

	const validatePassword = async (pageId: string, password: string) => {
		const payload = { password, pageId, metaSiteId, siteId }
		const response = await fetchApi.envFetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})

		return await response.json()
	}

	const getPageJsonName = (pageJsonUrl: string) => {
		const urlPath = pageJsonUrl.split('/').pop() || ''
		return urlPath.split('.')[0]
	}

	const promptDialog = (props: PasswordDialogProps) => {
		propsStore.update({ [DialogComponentId]: props })
		structureApi.addComponentToDynamicStructure(DialogComponentId, {
			componentType: 'EnterPasswordDialog',
			components: [],
		})
	}

	return {
		appDidMount: () => {
			if (pendingDialogProps) {
				promptDialog(pendingDialogProps)
				pendingDialogProps = null
			}
			appMounted = true
		},
		promptPasswordDialog: (pageId: string) => {
			return new Promise(async (resolve) => {
				hideDialog()

				const isDirectNavigation = !currentRouteInfo.getCurrentRouteInfo()
				const isHomePage = siteFeatureConfig.homePageId === pageId

				const props = {
					errorCode: 0,
					isCloseable: !isDirectNavigation || !isHomePage,
					onCloseDialogCallback: () => {
						hideDialog()
						if (isDirectNavigation) {
							resolve({})
						}
					},
					onSubmitCallback: async (password: string) => {
						const data = await validatePassword(pageId, password)

						if (data.errorCode) {
							propsStore.update({
								[DialogComponentId]: {
									errorCode: data.errorCode,
								},
							})
						} else {
							const pageJsonUrl = data.payload.urls[0]
							const pageJsonName = getPageJsonName(pageJsonUrl)
							hideDialog()
							resolve({ [pageId]: pageJsonName })
						}
					},
				}
				if (isDirectNavigation && !appMounted) {
					// wait until app mounts before displaying dialog - https://jira.wixpress.com/browse/TB-1103
					pendingDialogProps = props
				} else {
					promptDialog(props)
				}
			})
		},
	}
}

export const PasswordProtectedPageApi = withDependencies(
	[Props, StructureAPI, Fetch, named(SiteFeatureConfigSymbol, name), ViewerModelSym, CurrentRouteInfoSymbol],
	passwordProtectedPageApi
)
